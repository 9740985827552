/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import 'quill/dist/quill.bubble.css';

:root{ // this will be overwritten by the api theme
  --color-primary: #FBC00E;
  --color-secondary: #326CB7;
  --color-tertiary: #326CB7;
  --color-warning: #DA2727;
  --color-success: #26C75C;
  --color-info: #A82283;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;

  //.ql-mention-list-container {
  //  width: 100px;
  //}

  .ql-mention-list-container {
    height: 250px;
    left: 10px;
  }
  .ql-mention-list-item {
    padding: 0;
  }
  .ql-editor[contenteditable="false"] {
      padding: 1px;
    }

  @include mat.theme((
    color: (
      theme-type: light,
      primary: mat.$azure-palette,
      tertiary: mat.$blue-palette,
    ),
    typography: Roboto,
    density: 0,
  ));}

:root {
  --hour-size: 100px;
}

:root {
  @include mat.dialog-overrides((
    content-padding: 0
  ));
}

:root {
  @include mat.form-field-overrides((
    //filled-caret-color: orange,
    //filled-focus-active-indicator-color: red,
    filled-container-color: transparent
  ));
}

// toggle slider
:root {
  @include mat.slide-toggle-overrides((

    unselected-focus-track-color: var(--color-secondary),
    track-outline-color: var(--color-secondary),
    selected-track-color: var(--color-secondary),
    selected-focus-track-color: var(--color-secondary),
    selected-hover-track-color: var(--color-secondary),
    selected-pressed-track-color: var(--color-secondary),
    unselected-track-color: var(--color-secondary),
    unselected-pressed-track-color: var(--color-secondary),
    selected-icon-color: white,
    unselected-icon-color: white,
    selected-handle-color: white,
    selected-focus-handle-color: white,
    selected-hover-handle-color: white,
    disabled-selected-icon-color: white,
    unselected-handle-color: white,
    unselected-pressed-handle-color: white,
    unselected-hover-handle-color: white,
    unselected-focus-handle-color: white,

    //unselected-handle-size: 20px,
    //selected-handle-size: 20px,
    //pressed-handle-size: 20px,
    //track-height: 24px,
    //track-width: 49px,

  ));
}


:root {
  @include mat.checkbox-overrides((
    //disabled-label-color: orange,
    //label-text-color: red,
    selected-icon-color:var(--color-primary),
    selected-focus-icon-color: var(--color-primary),
    selected-hover-icon-color: var(--color-primary),
    selected-focus-state-layer-color: var(--color-primary),
    selected-pressed-state-layer-color: var(--color-primary),
    selected-hover-state-layer-color: var(--color-primary),
    unselected-focus-state-layer-color: var(--color-primary),
    unselected-pressed-state-layer-color: var(--color-primary),
    unselected-hover-state-layer-color: var(--color-primary),

  ));
  .mat-mdc-table {
    border-radius: 12px;
    margin-top: 15px;

    .mat-mdc-header-cell {
      background: rgb(from var(--color-secondary) r g b / 20%);
    }

    .mat-mdc-header-cell:first-child {
      border-radius: 20px 0 0 0;
    }

    .mat-mdc-header-cell:last-child {
      border-radius: 0 20px 0 0;
    }

    .mat-mdc-row:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.02);
      border: none;
    }

    td {
      border: none;
    }
  }
  .mat-mdc-dialog-component-host{
    .header{
      background-color: rgb(from var(--color-secondary) r g b / 20%);
    }
  }
}

.short-form-field {
  height: 45px;
  font-size: 16px; /* Ensuring readable text */
  .mat-mdc-form-field-flex{
    height: 45px;
  }
  .mat-mdc-form-field-infix{
    padding: 13px 0 !important;
  }
}

.custom-dialog-container .mat-dialog-container {
  overflow: unset;
}

.page-title {
  color: var(--color-secondary);
  font-weight: bold;
  font-size: 1.4rem;
}

.assignee-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  min-width: 206px;
}

.relationship-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  min-width: 228px;
}

.save-small {
  height: 24px;
  width: 24px;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  border-radius: 50%;
  color: #000;
  background-color: var(--color-primary);

  mat-icon {
    color: black;
    font-size: 24px;
  }
}

.cancel-small {
  height: 24px;
  width: 24px;
  cursor: pointer;
  border: 1px solid #00000088;
  border-radius: 50%;
  background-color: #FFF;
  mat-icon {
    color: #000000AA;
    font-size: 24px;
  }
}

.trash-small {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 24px;
  width: 24px;
  cursor: pointer;
  border: 1px solid #DA2727;
  border-radius: 50%;
  background-color: #FFF;
  mat-icon {
    color: #DA2727;
    height: 20px;
    width: 20px;
    font-size: 20px;
  }
}

.save {
  height: 30px;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  color: #000;
  background-color: var(--color-primary);
  gap: 5px;
}

.cancel {
  height: 30px;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  border: 1px solid #5F6368;
  color: #5F6368;
  border-radius: 4px;
  gap: 5px;
}

.success-snackbar{
  div {
    background-color: #26C75C !important;
  }
}
.error-snackbar {
  div {
    background-color: #DA2727 !important;
  }
}
.warning-snackbar {
  div {
    background: orange !important;
  }
}

.notification-snackbar {
  div {
    background: #A82283 !important;
  }
}

.NgxEditor__MenuBar {
  border: rgb(200, 200, 200) 1px solid;
  border-radius: 6px;
}

.open_new_new_icon {
  color: var(--color-secondary);
  font-size: 16px;
  width: 16px;
  height: 16px;
  margin-top: 8px;
  cursor: pointer;
}

.contact-person {
  cursor: pointer;
}

.gm-mention-node {
  color: #414040 !important;
  background: #d3d3d3;
  border-radius: 19px;
  padding: 4px 7px;
  font-weight: initial;
  white-space: pre;
  line-height: 2;
}

.prosemirror-tag-node {
  color: gray !important;
  font-style: italic;
}

.suggestion-item-list {
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.suggestion-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.suggestion-item:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

::ng-deep .mat-mdc-form-field-bottom-align {
  display: none !important; //xunxo
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


.error-label {
  color: var(--color-warning) !important;
}

.cdk-global-scrollblock {
  overflow: hidden !important;
}

.editable:hover{
  background-color: #0000000D;
}
